<template>
  <v-skeleton-loader :loading="status.reading" type="article">
    <v-sheet v-bind="propsCompSheet" data-cy="folders-delete">
      <div class="mb-8">{{ $t("folders.deleteConfirmation") }}</div>

      <v-container class="pa-0 mb-8">
        <v-row>
          <v-col cols="12" md="2">
            <div class="label-show">{{ $t("common.name") }}:</div>
          </v-col>
          <v-col cols="12" md="10">
            <div>{{ folder.name }}</div>
          </v-col>
        </v-row>
      </v-container>

      <div class="d-flex">
        <v-btn
          v-bind="propsButton"
          :loading="status.loading"
          :to="{ name: 'home' }"
          data-cy="button-cancel"
          >{{ $t("common.cancel") }}</v-btn
        >
        <div class="flex-grow-1"></div>
        <v-btn
          v-if="
            authCanByUserId('deleteOwn.folders', folder.ownerId) ||
            authCan('deleteOthers.folders')
          "
          v-bind="propsButtonTrash"
          :loading="status.loading"
          :disabled="!formRemove"
          @click="clickRemove"
          data-cy="button-remove"
          >{{ $t("common.yes") }}</v-btn
        >
      </div>
    </v-sheet>
  </v-skeleton-loader>
</template>

<script>
import Permissions from "@/modules/auth/mixins/Permissions.mixin.js";
import ComponentStatus from "@/modules/base/componentStatus.mixin";

export default {
  name: "FoldersDelete",
  mixins: [Permissions, ComponentStatus],
  components: {},
  props: {
    objectId: {
      type: String,
      default: "",
    },
  },
  data: () => ({}),
  computed: {
    formDisabled() {
      // if (this.isAdmin) {
      //   return false;
      // }
      return false;
    },
    formRemove() {
      // if (this.isAdmin) {
      //   return true;
      // }
      return true;
    },
    formReadyForSubmit() {
      if (this.status.loading) {
        return false;
      }
      if (this.status.readError) {
        return false;
      }
      // if (this.isAdmin) {
      //   return true;
      // }
      if (this.$v.$invalid) {
        return false;
      }
      if (!this.$v.$anyDirty) {
        return false;
      }
      return true;
    },
    // 2) When the folder id changes we use the store getter to "get" the
    // object that has (or the temporary one that will have) the folder data.
    // Note that this is different than the store action used to read the
    // actual data from the backend, they both work "in parallel".
    folder() {
      return this.$store.getters["folders/readById"](this.objectId);
    },
  },
  watch: {
    // 3) If the folder id changes and we have not edit any field we trigger
    // the store action to load the data. Note that this is different than
    // the getter used to display the data, they both work "in parallel".
    objectId: {
      immediate: true,
      handler: function () {
        this.setStatusReading();
        this.editFolderId = this.objectId;
        this.$store
          .dispatch("folders/readById", this.objectId)
          .then((/* result */) => {
            this.setStatusReadSuccess();
          })
          .catch((/* error */) => {
            this.setStatusReadError();
          });
      },
    },
  },
  created() {},
  methods: {
    clickRemove() {
      this.setStatusDeleting();
      this.$store
        .dispatch("folders/delete", this.editFolderId)
        .then((/* result */) => {
          this.setStatusDeleteSuccess();
          this.$store.commit("status/showSuccess");
          this.$router.push({ name: "home" });
        })
        .catch((/* error */) => {
          this.setStatusDeleteError();
          this.$store.commit("status/showError");
        });
    },
  },
};
</script>
